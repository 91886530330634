.buttonLink {
  --box-shadow: none;
  --background-color: transparent;
  text-decoration: underline;
  text-transform: none;
  --ion-color-base: transparent !important;
  font-size: 11px;
}

.newTypeOfModal {
  margin-top: 40px;
}
